#leftslice-outer-left {
  font-family: "Spartan";
  -ms-transform: skew(-30deg, 0deg);
  -webkit-transform: skew(-30deg, 0deg);
  transform: skew(-30deg, 0deg);
  background: #333333;
  width: 70%;
  height: 200px;
  z-index: 20;
  border-bottom: 3px solid #000000af;
  padding: 10px 30px 10px 75px;
  color: #ffffff;
  position: relative;
  left: -100px;
  box-shadow: 1px 15px 15px rgb(16, 16, 16);
  overflow-x: hidden;
  overflow-y: hidden;
}

#leftslice-popup {
  overflow-y: hidden;
}

.leftslice-headingtext {
  padding-top: 20px;
  padding-left: 60px;
  padding-bottom: 20px;
  font-weight: 500;
  font-size: 2.3rem;
}

#leftslice-outer-left:after {
  content: "";
  display: inline-block;
  position: absolute;
  width: 20px;
  height: 100%;
  right: -3px;
  top: 0px;
  z-index: 10;

  background: linear-gradient(0deg, #1b267e 0%, #3c0b46 100%);
  background-size: 200% 200%;
  animation: bg-animation 10s infinite alternate;
  -webkit-animation: bg-animation 10s infinite alternate;
}

#leftslice-inner-left {
  -ms-transform: skew(30deg, 0deg);
  -webkit-transform: skew(30deg, 0deg);
  transform: skew(30deg, 0deg);
  display: inline-block;
}

.leftslice-link a:after {
  content: "";
  position: absolute;
  background-color: rgb(39, 51, 141);
  z-index: -1;
}
.leftslice-link a:hover {
  color: #fff;
}

/* Directions */

button.left:after {
  left: -0.3em;
  top: 0;
  height: 100%;
  width: 0.08em;
  transform: skew(-20deg);
  transition: width 0.1s;
}
.leftslice-link a:hover:after {
  width: calc(100% + 0.6em);
}

.leftslice-link a:after {
  right: -0.3em;
  top: 0;
  height: 100%;
  width: 0.08em;
  transform: skew(-20deg);
  transition: width 0.1s;
}
.leftslice-link a:hover:after {
  width: calc(100% + 0.6em);
}

/* Tag styling */

.leftslice-tags {
  top: -15px;
  position: relative;
  width: 100%;
  padding-left: 12%;
}
.leftslice-tags li,
.leftslice-tags p {
  float: left;
  height: 26px;
  line-height: 24px;
  position: relative;
  font-size: 15px;
}
.leftslice-tags p {
  margin-left: 20px;
  padding: 0 10px 0 12px;
  background: rgb(142, 68, 216);
  color: #fff;
  text-decoration: none;
  -moz-border-radius-bottomright: 4px;
  -webkit-border-bottom-right-radius: 4px;
  border-bottom-right-radius: 4px;
  -moz-border-radius-topright: 4px;
  -webkit-border-top-right-radius: 4px;
  border-top-right-radius: 4px;
}
.leftslice-tags p:before {
  content: "";
  float: left;
  position: absolute;
  top: 0;
  left: -12px;
  width: 0;
  height: 0;
  border-color: transparent rgb(142, 68, 216) transparent transparent;
  border-style: solid;
  border-width: 12px 12px 14px 0;
}
.leftslice-tags p:after {
  content: "";
  position: absolute;
  top: 10px;
  left: 0;
  float: left;
  width: 4px;
  height: 4px;
  -moz-border-radius: 2px;
  -webkit-border-radius: 2px;
  border-radius: 2px;
  background: #fff;
  -moz-box-shadow: -1px -1px 2px #004977;
  -webkit-box-shadow: -1px -1px 2px #004977;
  box-shadow: -1px -1px 2px #004977;
}

/* slice image styling */

.leftslice-img-container {
  position: absolute;
  width: 70%;
  height: 100%;
  overflow: hidden;
  top: -10px;
  left: 50%;
  margin-right: 0%;
  -ms-transform: skew(30deg, 0deg);
  -webkit-transform: skew(30deg, 0deg);
  transform: skew(30deg, 0deg);
}
.leftslice-img {
  position: absolute;
  left: 1px;
  z-index: -2;
  min-width: 100%;
  min-height: 100%;
}

.fade {
  position: absolute;
  z-index: -1;
  height: 100%;
  width: 30%;
  position: absolute;
  background: linear-gradient(to right, #333333, transparent 90%);
}

/* Button Div positioning */

.leftslice-sourcecode {
  position: relative;
  top: 85px;
  left: -70px;
}

.leftslice-project-live {
  position: relative;
  top: 85px;
  left: 130px;
}

.leftslice-read-more {
  position: relative;
  top: 85px;
  left: 370px;
}
.leftslice-buttons {
  position: absolute;
  left: 220px;
}

/* Scaling for window Sizes */

@media (max-width: 1579px) {
  .leftslice-headingtext {
    padding-top: 10px;
    font-size: 1.6em;
  }
  .leftslice-tags {
    padding-left: 50px;
  }
}

@media (max-width: 1079px) {
  .leftslice-sourcecode {
    left: -70px;
  }
  .leftslice-project-live {
    left: 100px;
  }
  .leftslice-read-more {
    left: 310px;
  }
}

@media (max-width: 949px) {
  .leftslice-sourcecode {
    left: -90px;
  }
  .leftslice-project-live {
    left: 50px;
  }
  .leftslice-read-more {
    left: 222px;
  }
}

@media (max-width: 779px) {
  #leftslice-outer-left {
    width: 80%;
  }
  .leftslice-headingtext {
    padding-top: 10px;
    font-size: 1.5em;
    padding-left: 40px;
  }
  .leftslice-tags {
    padding-left: 8%;
  }
  .leftslice-buttons {
    left: 200px;
  }
}

@media (max-width: 579px) {
  #leftslice-outer-left {
    width: 73%;
  }
  .leftslice-headingtext {
    padding-top: 2%;
    padding-left: 10px;
  }
  .leftslice-tags {
    padding-left: 0px;
    padding-right: 100px;
    max-width: 300px;
  }
  .leftslice-read-more {
    top: 120px;
    left: -35px;
  }
  .leftslice-project-live {
    top: 170px;
    left: -75px;
  }
  .leftslice-sourcecode {
    top: 220px;
    left: -70px;
  }
  #leftslice-outer-left {
    height: auto;
    padding-bottom: 150px;
  }
  .leftslice-buttons {
    position: absolute;
    left: 150px;
  }
}

@media (max-width: 320px) {
  #leftslice-outer-left {
    width: 75%;
    overflow-x: hidden;
    overflow-y: hidden;
  }
  .leftslice-headingtext {
    padding-left: 30px;
    font-size: 1em;
    padding-bottom: 0px;
  }
}
/* Background Animations */

@keyframes bg-animation {
  0% {
    background-position: 10% 0%;
  }
  50% {
    background-position: 91% 100%;
  }
  100% {
    background-position: 10% 0%;
  }
}

@-webkit-keyframes bg-animation {
  0% {
    background-position: 10% 0%;
  }
  50% {
    background-position: 91% 100%;
  }
  100% {
    background-position: 10% 0%;
  }
}
