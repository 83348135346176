@import url(https://fonts.googleapis.com/css?family=Spartan);
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}



.navbar {
  font-family: "Spartan";
  width: 100%;
  height: 100px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  background-color: rgba(0, 0, 0, 0.7);
  position: absolute;
  top: 0;
  z-index: 10;
  box-shadow: 0.2px 0.2px 10px rgb(0, 0, 0);
}

.brand-title {
  color: rgb(142, 68, 216);
  font-size: large;
  left: -20px;
  font-weight: 800;
}

.navbar ul {
  list-style: none;
}

.navbar li {
  display: inline-block;
}

.navbar li a {
  display: inline-block;
  margin-left: 40px;
  margin-right: 40px;
  border: none;
  cursor: pointer;
  position: relative;
  color: rgb(142, 68, 216);
  transition: color 0.2s;
  letter-spacing: 1px;
  text-decoration: none;
  padding: 20px;
  font-weight: 700;
  font-size: 1.4em;
}

.hamburger-items li a {
  display: inline-block;
  border: none;
  position: relative;
  color: rgb(142, 68, 216);
  transition: color 0.2s;
  letter-spacing: 1px;
  text-decoration: none;
  padding: 20px;
  font-weight: 700;
}

.navbar li a:after,
.hamburger-items li a:after {
  content: "";
  position: absolute;
  background-color: rgb(39, 51, 141);
  z-index: -1;
}
.navbar li a:hover,
.hamburger-items li a:hover {
  color: #fff;
  transition: all 0.5s;
}

/* Directions */
button.left:after {
  left: -0.3em;
  top: 0;
  height: 100%;
  width: 0.08em;
  -webkit-transform: skew(-20deg);
          transform: skew(-20deg);
  transition: width 0.1s;
}
.navbar li a:hover:after,
.hamburger-items li a:hover:after {
  width: calc(100% + 0.6em);
}

.navbar li a:after,
.hamburger-items li a:after {
  right: -0.3em;
  top: 0;
  height: 100%;
  width: 0.08em;
  -webkit-transform: skew(-20deg);
          transform: skew(-20deg);
  transition: width 0.1s;
}
.navbar li a:hover:after,
.hamburger-items li a:hover:after {
  width: calc(100% + 0.6em);
}

.hamburger {
  width: 35px;
  cursor: pointer;
  z-index: 1000;
  position: absolute;
  top: 25px;
  right: 30px;
}

.hamburger-menu {
  position: fixed;
  z-index: 100;
  min-width: 200px;
  max-width: 400px;
  width: 70%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.857);
  right: 0;
  top: 0;
  text-align: center;
}

.hamburger-items {
  list-style-type: none;
  text-align: center;
  padding: 25px 45px;
}

.hamburger-close {
  position: absolute;
  right: 10px;
  top: 10px;
  cursor: pointer;
}

.hamburger-item {
  list-style-type: none;
  padding: 20px;
  margin: 10px 0;
  margin-top: 20px;
  color: white;
  font-size: 30px;
}

.hamburger-background-blur {
  width: 100%;
  height: 100vh;
  position: fixed;
  background-color: rgba(95, 95, 95, 0.33);
  -webkit-backdrop-filter: blur(6px);
          backdrop-filter: blur(6px);
  z-index: 25;
  right: 0;
  top: 0;
}

/* Hamburger Menu Functionality */

@media only screen and (min-width: 950px) {
  .navbar li {
    display: inline;
  }
  .hamburger,
  .hamburger-menu,
  .hamburger-background-blur {
    display: hidden;
    display: none;
  }
}

@media only screen and (max-width: 950px) {
  .navbar li {
    display: none;
  }
  .hamburger {
    display: block;
  }
}

@media only screen and (max-width: 579px) {
  .hamburger-menu li a {
    font-size: 1.2rem;
  }
}

@media only screen and (max-width: 320px) {
  .hamburger-menu li a {
    font-size: 1.1rem;
    padding: 20px 5px 20px 5px;
  }
}

:root {
    --gradient: linear-gradient(45deg, #1b267e, #3c0b46, #6e207e);
}

#outer-left {
    font-family: 'Spartan';
    -webkit-transform: skew(-30deg, 0deg);
    transform: skew(-30deg, 0deg);
    background: #333333;
    width: 60%;
    height: 200px;
    z-index: 20;
    border-bottom: 3px solid #000000af;
    padding: 10px 30px 10px 75px;
    font-size: 20px;
    color: #ffffff;
    position: relative;
    left: -100px;
    box-shadow: 1px 15px 15px rgb(16, 16, 16);
}

.headingtext {
    padding-top: 20px;
    padding-left: 7%;
    padding-bottom: 20px;
    font-weight: 500;
    font-size: 2rem;
}

#outer-left:after {
    content: '';
    display: inline-block;
    position: absolute;
    width: 20px;
    height: 100%;
    right: 0px;
    top: 0px;
    z-index: 10;

    background: linear-gradient(0deg, #1b267e 0%, #3c0b46 100%);
    background-size: 200% 200%;
    animation: bg-animation 10s infinite alternate;
    -webkit-animation: bg-animation 10s infinite alternate;
}

#inner-left {
    -webkit-transform: skew(30deg, 0deg);
    transform: skew(30deg, 0deg);
    display: inline-block;
}
.link {
    display: inline-flex;
    align-items: center;
    justify-content: space-between;
    margin-top: 5px;
}

.link-icon {
    font-size: 60px;
    padding-left: -15px;
}
.link a {
    display: inline-block;
    margin-left: 40px;
    margin-right: 40px;
    border: none;
    cursor: pointer;
    position: relative;
    color: rgb(142, 68, 216);
    transition: color 0.2s;
    letter-spacing: 1px;
    text-decoration: none;
    padding: 20px;
    font-weight: 500;
    font-size: 25px;
}

.link a:after {
    content: '';
    position: absolute;
    background-color: rgb(39, 51, 141);
    z-index: -1;
}
.link a:hover {
    color: #fff;
}

/* Directions */

button.left:after {
    left: -0.3em;
    top: 0;
    height: 100%;
    width: 0.08em;
    -webkit-transform: skew(-20deg);
            transform: skew(-20deg);
    transition: width 0.1s;
}
.link a:hover:after {
    width: calc(100% + 0.6em);
}

.link a:after {
    right: -0.3em;
    top: 0;
    height: 100%;
    width: 0.08em;
    -webkit-transform: skew(-20deg);
            transform: skew(-20deg);
    transition: width 0.1s;
}
.link a:hover:after {
    width: calc(100% + 0.6em);
}

/* Scaling for window Sizes */

@media (max-width: 779px) {
    #outer-left {
        width: 80%;
        overflow-x: hidden;
        overflow-y: hidden;
    }
    .link a {
        font-size: 0.8em;
        margin-right: 20px;
        padding: 10px;
    }
    .headingtext {
        padding-top: 10px;
        font-size: 1.5em;
    }
    .link-icon {
        font-size: 30px;
    }
    .headingtext {
        padding-left: 10%;
    }
}

@media (max-width: 1550px) {
    .headingtext {
        padding-top: 10px;
        font-size: 1.5em;
    }
    .link-icon {
        font-size: 30px;
        padding-left: 20px;
        margin-right: 10px;
    }
    .link a {
        font-size: 1.3em;
        margin-right: 5px;
        padding: 6px;
    }
}
@media (max-width: 579px) {
    .headingtext {
        padding-top: 2%;
        font-size: 1em;
        padding-left: 14%;
    }
    .link a {
        font-size: 1em;
        margin-right: 15px;
        padding: 5px;
        padding-bottom: 10px;
    }
}

@media (max-width: 320px) {
    #outer-left {
        width: 75%;
        overflow-x: hidden;
        overflow-y: hidden;
    }
    .headingtext {
        padding-left: 30px;
        font-size: 1em;
        padding-bottom: 0px;
    }
    .link-icon {
        font-size: 20px;
        padding-left: 10px;
        margin-right: 10px;
    }
    .link a {
        padding: 0px;
        font-size: 1em;
        margin-right: 5px;
    }
}

/* Background Animations */

@keyframes bg-animation {
    0% {
        background-position: 10% 0%;
    }
    50% {
        background-position: 91% 100%;
    }
    100% {
        background-position: 10% 0%;
    }
}

@-webkit-keyframes bg-animation {
    0% {
        background-position: 10% 0%;
    }
    50% {
        background-position: 91% 100%;
    }
    100% {
        background-position: 10% 0%;
    }
}

.content-container {
  z-index: 1;
  position: relative;
}

.content-links {
  font-family: "Spartan";
  position: absolute;
  right: 0;
  z-index: 1;
  color: white;
  padding-top: 100px;
}

.content-text {
  font-family: "Spartan";
  position: absolute;
  z-index: 1;
  color: white;
  padding-left: 10%;
  padding-right: 10%;
  top: 70%;
}

.downcontent-text p {
  font-size: 1.2rem;
}

.downcontent-panel-top {
  height: 200px;
  clip-path: polygon(0 0, 50% 70px, 100% 0, 100% 100%, 0 100%);
  -webkit-clip-path: polygon(0 0, 50% 70px, 100% 0, 100% 100%, 0 100%);
  margin-top: -40px;

  background: linear-gradient(0deg, #1b267e 0%, #3c0b46 100%);
  background-size: 200% 200%;
  animation: bg-animation 10s infinite alternate;
  -webkit-animation: bg-animation 10s infinite alternate;
}

.downcontent-panel-bottom {
  height: 100px;
  clip-path: polygon(0% 100px, 50% 70px, 100% 100px, 100% 100%, 0 100%);
  -webkit-clip-path: polygon(0% 100px, 50% 70px, 100% 100px, 100% 100%, 0 100%);
  margin-top: -1px;
  -webkit-transform: rotateX(180deg);
          transform: rotateX(180deg);
  z-index: 1;

  background: linear-gradient(0deg, #1b267e 0%, #3c0b46 100%);
  background-size: 200% 200%;
  animation: bg-animation 10s infinite alternate;
  -webkit-animation: bg-animation 10s infinite alternate;
}

.downborder-top {
  background-color: rgba(0, 0, 0, 0.7);
  position: relative;
  -webkit-clip-path: polygon(0 0, 50% 70px, 100% 0, 100% 100%, 0 100%);
          clip-path: polygon(0 0, 50% 70px, 100% 0, 100% 100%, 0 100%);
  margin-top: -330px;
  height: 150px;
  z-index: -2;
}

@media only screen and (max-width: 779px) {
  .downcontent-panel-top {
    margin-top: -20px;
    height: 150px;
  }
  .downborder-top {
    margin-top: -270px;
    height: 150px;
  }
  .downcontent-panel-bottom {
    height: 100px;
    margin-bottom: -20px;
  }
}

/* Background Animations */

@keyframes bg-animation {
  0% {
    background-position: 10% 0%;
  }
  50% {
    background-position: 91% 100%;
  }
  100% {
    background-position: 10% 0%;
  }
}

@-webkit-keyframes bg-animation {
  0% {
    background-position: 10% 0%;
  }
  50% {
    background-position: 91% 100%;
  }
  100% {
    background-position: 10% 0%;
  }
}

body {
  margin: 0;
  padding: 0;
  background-color: #202d4d;
}

.banner-top {
  height: 500px;
  position: relative;
  width: 100%;
}

.banner-top img {
  width: 100%;
  height: 100%;
  background-size: cover;
  background-position: center center;
  z-index: -1;
}

.banner-top-heading {
  font-family: "Spartan";
  position: absolute;
  top: 40%;
  color: white;
  text-align: center;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
          transform: translate(-50%, -50%);
  font-size: 5rem;
  text-shadow: 1px 1px 5px rgb(162, 162, 162);
  white-space: nowrap;
}

.banner-top-subheading {
  font-family: "Spartan";
  position: absolute;
  top: 60%;
  color: white;
  text-align: center;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
          transform: translate(-50%, -50%);
  font-size: 1.5rem;
  text-shadow: 0.2px 0.2px 10px rgb(170, 170, 170);
}

.button-container {
  position: absolute;
  left: 50%;
  top: 77.5%;
  z-index: 10;
  -webkit-transform: translate(-50%, -50%);
          transform: translate(-50%, -50%);
}

/* Size Styling */

@media (max-width: 979px) {
  .banner-top-heading {
    font-size: 4rem;
  }
}

@media (max-width: 779px) {
  .content,
  .content-lower {
    height: 300px;
  }
  .content-lower:before,
  .content-lower::after {
    top: 0;
  }

  .banner-top-subheading {
    font-size: 1.2rem;
    top: 62%;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: clip;
  }
}

@media (max-width: 579px) {
  .banner-top-heading {
    font-size: 3rem;
    padding-top: 50px;
  }
  .banner-top {
    height: 400px;
  }
}

@media (max-width: 379px) {
  .banner-top-heading {
    font-size: 2rem;
  }
}

body {
  font-family: "Spartan";
}

.footer-content {
  font-family: "Spartan";
  z-index: 50;
  color: white;
  background: #333333;
  height: auto;
  width: 100%;
  display: flex;
  justify-content: space-around;
  align-content: center;
  overflow-x: hidden;
  overflow-y: hidden;
}

.footer-col1,
.footer-col2,
.footer-col3 {
  display: inline-block;
  align-content: space-around;
  justify-content: space-around;
}

.footer-col1-list,
.footer-col2-list,
.footer-col3-list {
  padding: 0px;
  vertical-align: middle;
  text-decoration: none;
}

.footer-col2-list a,
.footer-col2-list p {
  color: white;
  text-align: center;
}

.footer-col3-list a,
.footer-col3-list p {
  color: white;
  text-align: right;
}

.footer-topdecoration {
  -webkit-transform: translateY(130px);
          transform: translateY(130px);
  height: 30px;
  content: "";
  bottom: 20px;

  background: linear-gradient(45deg, #1b267e 0%, #3c0b46 100%);
  background-size: 200% 200%;
  animation: bg-animation 10s infinite alternate;
  -webkit-animation: bg-animation 10s infinite alternate;
}
@media only screen and (max-width: 650px) {
  .footer-col1,
  .footer-col2,
  .footer-col3,
  .footer-content {
    display: block;
  }
  .footer-col1-list a,
  .footer-col1-list p,
  .footer-col3-list a,
  .footer-col3-list p,
  .footer-col1-head,
  .footer-col2-head,
  .footer-col3-head {
    text-align: center;
  }
}

/* Background Animations */

@keyframes bg-animation {
  0% {
    background-position: 10% 0%;
  }
  50% {
    background-position: 91% 100%;
  }
  100% {
    background-position: 10% 0%;
  }
}

@-webkit-keyframes bg-animation {
  0% {
    background-position: 10% 0%;
  }
  50% {
    background-position: 91% 100%;
  }
  100% {
    background-position: 10% 0%;
  }
}

.button-main {
  display: inline-flex;
  align-items: center;
  justify-content: space-between;
  margin-top: 5px;
}

.button-main a {
  display: inline-block;
  margin-left: 40px;
  margin-right: 40px;
  border: none;
  cursor: pointer;
  position: relative;
  color: rgb(255, 255, 255);
  transition: color 0.2s;
  letter-spacing: 1px;
  text-decoration: none;
  padding: 20px;
  font-weight: 600;
  font-size: 25px;
  white-space: nowrap;
}

.button-icon {
  font-size: 35px;
  margin-left: 8px;
  margin-bottom: 10px;
  vertical-align: middle;
}

/* Button background styling before hover */

.button-main a:before {
  content: "";
  position: absolute;
  background-color: rgba(0, 0, 0, 0.7);
  z-index: -1;
}
.button-main a:before {
  color: #fff;
}

button.left:before {
  left: -0.3em;
  top: 0;
  height: 100%;
  width: 0.08em;
  -webkit-transform: skew(-20deg);
          transform: skew(-20deg);
  transition: width 0.1s;
}

.button-main a:before {
  right: -0.3em;
  top: 0;
  height: 90%;
  width: calc(100% + 0.6em);
  -webkit-transform: skew(-20deg);
          transform: skew(-20deg);
  transition: width 0.1s;
}

.button-main a:after {
  content: "";
  position: absolute;
  background-color: rgb(39, 51, 141);
  z-index: -1;
}
.button-main a:hover {
  color: #fff;
}

/* Directions */

button.left:after {
  left: -0.3em;
  top: 0;
  height: 90%;
  width: 0.08em;
  -webkit-transform: skew(-20deg);
          transform: skew(-20deg);
  transition: width 0.1s;
}
.button-main a:hover:after {
  width: calc(100% + 0.6em);
}

.button-main a:after {
  right: -0.3em;
  top: 0;
  height: 90%;
  width: 0.08em;
  -webkit-transform: skew(-20deg);
          transform: skew(-20deg);
  transition: width 0.1s;
}
.button-main a:hover:after {
  width: calc(100% + 0.6em);
}

/* Scaling on different sizes */

@media (max-width: 979px) {
  .button-main a:before,
  .button.left:after,
  .button-main a:after {
    height: 60%;
    top: 12px;
  }
  .button-main a {
    font-size: 20px;
    padding-bottom: 20px;
    letter-spacing: 0.2px;
  }
  .button-icon {
    font-size: 25px;
    margin-left: 4px;
    margin-bottom: 8px;
    vertical-align: middle;
  }
}

html {
  height: 100%;
}

body {
  margin: 0;
  background-color: rgb(22, 22, 28);
  height: 100%;
  overflow-x: hidden;
}

.encompass-home {
  display: flex;
  flex-direction: column;
  height: 100vh;
}

.home-container {
  flex: 1 0 auto;
}

.top-divider-banner {
  position: relative;
  top: -50px;
}

.second-divider-banner {
  position: relative;
  top: 450px;
  box-shadow: 10px 5px 5px black;
}

.content-1 {
  position: relative;
  top: 100px;
}

.content-2 {
  position: relative;
  top: 200px;
}

footer {
  color: white;
  justify-self: flex-end;
  margin-top: 130px;
}

@media (max-width: 779px) {
  .content-1 {
    top: 40px;
  }
  .content-2 {
    top: 100px;
    margin-bottom: -115px;
  }
}
body {
  font-family: "Spartan";
  margin: 0;
  z-index: 30;
}

.paragraph-content {
  position: relative;
  z-index: 20;
  width: 90%;
  background: #333333;
  margin-left: 5%;
  margin-right: 5%;
  box-shadow: 1px 5px 15px rgb(16, 16, 16);
  -moz-box-shadow: 1px 5px 15px rgb(16, 16, 16);
  -webkit-box-shadow: 1px 5px 15px rgb(16, 16, 16);
}

.paragraph-topdecoration {
  height: 50px;
  content: "";
}

.paragraph-topdecoration:after {
  content: "";
  position: absolute;
  top: -60px;
  height: 60px;
  width: 100%;
  bottom: 140px;
  right: -11px;
  -webkit-transform: translateY(50px);
          transform: translateY(50px);
  z-index: -1;
  -moz-transform: skew(-20deg);
  -webkit-transform: skew(-20deg);
  box-shadow: 1px 5px 15px rgb(16, 16, 16);
  -moz-box-shadow: 1px 5px 15px rgb(16, 16, 16);
  -webkit-box-shadow: 1px 5px 15px rgb(16, 16, 16);

  background: linear-gradient(45deg, #1b267e 0%, #3c0b46 100%);
  background-size: 200% 200%;
  animation: bg-animation 10s infinite alternate;
  -webkit-animation: bg-animation 10s infinite alternate;
}

.paragraph-header {
  color: white;
  font-size: 30px;
  align-content: center;
  padding-left: 5%;
  padding-right: 5%;
  padding-bottom: 20px;
}

.paragraph-text {
  color: white;
  font-size: 25px;
  align-content: center;
  padding-left: 5%;
  padding-right: 5%;
  padding-bottom: 20px;
  padding-top: 20px;
  overflow-wrap: break-word;
}

@media only screen and (max-width: 950px) {
  .paragraph-topdecoration:after {
    content: "";
    position: absolute;
    top: -20px;
    height: 20px;
    width: 100%;
    bottom: 140px;
    right: 0px;
    -webkit-transform: translateY(50px);
            transform: translateY(50px);
    background: linear-gradient(90deg, #1b267e 0%, #3c0b46 100%);
    z-index: -1;
    -moz-transform: skew(0deg);
    -webkit-transform: skew(0deg);
  }
}

@media only screen and (max-width: 579px) {
  .paragraph-text {
    font-size: 16px;
  }
  .paragraph-header {
    padding-bottom: 0px;
  }
}

/* Background Animations */

@keyframes bg-animation {
  0% {
    background-position: 10% 0%;
  }
  50% {
    background-position: 91% 100%;
  }
  100% {
    background-position: 10% 0%;
  }
}

@-webkit-keyframes bg-animation {
  0% {
    background-position: 10% 0%;
  }
  50% {
    background-position: 91% 100%;
  }
  100% {
    background-position: 10% 0%;
  }
}

html {
  height: 100%;
}

body {
  margin: 0;
  background-color: rgb(22, 22, 28);
  height: 100%;
  overflow-x: hidden;
}

.encompass-about {
  display: flex;
  flex-direction: column;
  height: 100vh;
}

.about-container {
  flex: 1 0 auto;
}

.about-content {
  position: relative;
  top: 160px;
}

footer {
  color: white;
  justify-self: flex-end;
  margin-top: 130px;
}

.about-icon {
  background-color: rgb(33, 33, 33);
  width: 100px;
  height: 100px;
  box-shadow: black 0px 0px 10px 1px;
  padding: 5px;
  margin: 9px;
}

.about-iconlist {
  text-align: center;
}

@media (max-width: 779px) {
  .about-content {
    top: 50px;
    margin-bottom: -170px;
  }
  .about-icon {
    size: 20px;
    font-size: 1.5em;
    height: 50px;
    width: 50px;
  }
}
#leftslice-outer-left {
  font-family: "Spartan";
  -webkit-transform: skew(-30deg, 0deg);
  transform: skew(-30deg, 0deg);
  background: #333333;
  width: 70%;
  height: 200px;
  z-index: 20;
  border-bottom: 3px solid #000000af;
  padding: 10px 30px 10px 75px;
  color: #ffffff;
  position: relative;
  left: -100px;
  box-shadow: 1px 15px 15px rgb(16, 16, 16);
  overflow-x: hidden;
  overflow-y: hidden;
}

#leftslice-popup {
  overflow-y: hidden;
}

.leftslice-headingtext {
  padding-top: 20px;
  padding-left: 60px;
  padding-bottom: 20px;
  font-weight: 500;
  font-size: 2.3rem;
}

#leftslice-outer-left:after {
  content: "";
  display: inline-block;
  position: absolute;
  width: 20px;
  height: 100%;
  right: -3px;
  top: 0px;
  z-index: 10;

  background: linear-gradient(0deg, #1b267e 0%, #3c0b46 100%);
  background-size: 200% 200%;
  animation: bg-animation 10s infinite alternate;
  -webkit-animation: bg-animation 10s infinite alternate;
}

#leftslice-inner-left {
  -webkit-transform: skew(30deg, 0deg);
  transform: skew(30deg, 0deg);
  display: inline-block;
}

.leftslice-link a:after {
  content: "";
  position: absolute;
  background-color: rgb(39, 51, 141);
  z-index: -1;
}
.leftslice-link a:hover {
  color: #fff;
}

/* Directions */

button.left:after {
  left: -0.3em;
  top: 0;
  height: 100%;
  width: 0.08em;
  -webkit-transform: skew(-20deg);
          transform: skew(-20deg);
  transition: width 0.1s;
}
.leftslice-link a:hover:after {
  width: calc(100% + 0.6em);
}

.leftslice-link a:after {
  right: -0.3em;
  top: 0;
  height: 100%;
  width: 0.08em;
  -webkit-transform: skew(-20deg);
          transform: skew(-20deg);
  transition: width 0.1s;
}
.leftslice-link a:hover:after {
  width: calc(100% + 0.6em);
}

/* Tag styling */

.leftslice-tags {
  top: -15px;
  position: relative;
  width: 100%;
  padding-left: 12%;
}
.leftslice-tags li,
.leftslice-tags p {
  float: left;
  height: 26px;
  line-height: 24px;
  position: relative;
  font-size: 15px;
}
.leftslice-tags p {
  margin-left: 20px;
  padding: 0 10px 0 12px;
  background: rgb(142, 68, 216);
  color: #fff;
  text-decoration: none;
  border-bottom-right-radius: 4px;
  border-top-right-radius: 4px;
}
.leftslice-tags p:before {
  content: "";
  float: left;
  position: absolute;
  top: 0;
  left: -12px;
  width: 0;
  height: 0;
  border-color: transparent rgb(142, 68, 216) transparent transparent;
  border-style: solid;
  border-width: 12px 12px 14px 0;
}
.leftslice-tags p:after {
  content: "";
  position: absolute;
  top: 10px;
  left: 0;
  float: left;
  width: 4px;
  height: 4px;
  border-radius: 2px;
  background: #fff;
  box-shadow: -1px -1px 2px #004977;
}

/* slice image styling */

.leftslice-img-container {
  position: absolute;
  width: 70%;
  height: 100%;
  overflow: hidden;
  top: -10px;
  left: 50%;
  margin-right: 0%;
  -webkit-transform: skew(30deg, 0deg);
  transform: skew(30deg, 0deg);
}
.leftslice-img {
  position: absolute;
  left: 1px;
  z-index: -2;
  min-width: 100%;
  min-height: 100%;
}

.fade {
  position: absolute;
  z-index: -1;
  height: 100%;
  width: 30%;
  position: absolute;
  background: linear-gradient(to right, #333333, transparent 90%);
}

/* Button Div positioning */

.leftslice-sourcecode {
  position: relative;
  top: 85px;
  left: -70px;
}

.leftslice-project-live {
  position: relative;
  top: 85px;
  left: 130px;
}

.leftslice-read-more {
  position: relative;
  top: 85px;
  left: 370px;
}
.leftslice-buttons {
  position: absolute;
  left: 220px;
}

/* Scaling for window Sizes */

@media (max-width: 1579px) {
  .leftslice-headingtext {
    padding-top: 10px;
    font-size: 1.6em;
  }
  .leftslice-tags {
    padding-left: 50px;
  }
}

@media (max-width: 1079px) {
  .leftslice-sourcecode {
    left: -70px;
  }
  .leftslice-project-live {
    left: 100px;
  }
  .leftslice-read-more {
    left: 310px;
  }
}

@media (max-width: 949px) {
  .leftslice-sourcecode {
    left: -90px;
  }
  .leftslice-project-live {
    left: 50px;
  }
  .leftslice-read-more {
    left: 222px;
  }
}

@media (max-width: 779px) {
  #leftslice-outer-left {
    width: 80%;
  }
  .leftslice-headingtext {
    padding-top: 10px;
    font-size: 1.5em;
    padding-left: 40px;
  }
  .leftslice-tags {
    padding-left: 8%;
  }
  .leftslice-buttons {
    left: 200px;
  }
}

@media (max-width: 579px) {
  #leftslice-outer-left {
    width: 73%;
  }
  .leftslice-headingtext {
    padding-top: 2%;
    padding-left: 10px;
  }
  .leftslice-tags {
    padding-left: 0px;
    padding-right: 100px;
    max-width: 300px;
  }
  .leftslice-read-more {
    top: 120px;
    left: -35px;
  }
  .leftslice-project-live {
    top: 170px;
    left: -75px;
  }
  .leftslice-sourcecode {
    top: 220px;
    left: -70px;
  }
  #leftslice-outer-left {
    height: auto;
    padding-bottom: 150px;
  }
  .leftslice-buttons {
    position: absolute;
    left: 150px;
  }
}

@media (max-width: 320px) {
  #leftslice-outer-left {
    width: 75%;
    overflow-x: hidden;
    overflow-y: hidden;
  }
  .leftslice-headingtext {
    padding-left: 30px;
    font-size: 1em;
    padding-bottom: 0px;
  }
}
/* Background Animations */

@keyframes bg-animation {
  0% {
    background-position: 10% 0%;
  }
  50% {
    background-position: 91% 100%;
  }
  100% {
    background-position: 10% 0%;
  }
}

@-webkit-keyframes bg-animation {
  0% {
    background-position: 10% 0%;
  }
  50% {
    background-position: 91% 100%;
  }
  100% {
    background-position: 10% 0%;
  }
}

.project-popup-container {
  position: "fixed";
  top: "50%";
  left: "50%";
  -webkit-transform: "translate(-50%, -50%)";
          transform: "translate(-50%, -50%)";
  background: "#FFF";
  padding: "50px";
  z-index: 1000;
}

.project-popup-heading {
  color: white;
  padding-left: 4%;
  font-size: 2rem;
}

.project-popup-content {
  color: white;
  padding-left: 4%;
  font-size: 1.5rem;
  width: 70%;
}

@media (max-width: 579px) {
  .project-popup-content {
    width: 92%;
  }
}

html {
  height: 100%;
}

body {
  margin: 0;
  background-color: rgb(22, 22, 28);
  height: 100%;
  overflow-x: hidden;
}

.encompass-projects {
  display: flex;
  flex-direction: column;
  height: 100vh;
}

.projects-container {
  flex: 1 0 auto;
}

.top-divider-banner {
  position: relative;
  bottom: -40px;
}

.project1-display {
  position: relative;
  top: 100px;
  transition: top 1s, left 1s;
}

.project2-display {
  position: relative;
  top: 150px;
  transition: all 0.5s;
}

.project3-display {
  position: relative;
  top: 200px;
}

footer {
  color: white;
  justify-self: flex-end;
  margin-top: 130px;
}

@media (max-width: 779px) {
  .project1-display {
    top: 40px;
  }
  .project2-display {
    top: 70px;
  }
  .project3-display {
    top: 100px;
    margin-bottom: -120px;
  }
}
