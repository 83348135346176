body {
  font-family: "Spartan";
  margin: 0;
  z-index: 30;
}

.paragraph-content {
  position: relative;
  z-index: 20;
  width: 90%;
  background: #333333;
  margin-left: 5%;
  margin-right: 5%;
  box-shadow: 1px 5px 15px rgb(16, 16, 16);
  -moz-box-shadow: 1px 5px 15px rgb(16, 16, 16);
  -webkit-box-shadow: 1px 5px 15px rgb(16, 16, 16);
}

.paragraph-topdecoration {
  height: 50px;
  content: "";
}

.paragraph-topdecoration:after {
  content: "";
  position: absolute;
  top: -60px;
  height: 60px;
  width: 100%;
  bottom: 140px;
  right: -11px;
  transform: translateY(50px);
  z-index: -1;
  -moz-transform: skew(-20deg);
  -webkit-transform: skew(-20deg);
  box-shadow: 1px 5px 15px rgb(16, 16, 16);
  -moz-box-shadow: 1px 5px 15px rgb(16, 16, 16);
  -webkit-box-shadow: 1px 5px 15px rgb(16, 16, 16);

  background: linear-gradient(45deg, #1b267e 0%, #3c0b46 100%);
  background-size: 200% 200%;
  animation: bg-animation 10s infinite alternate;
  -webkit-animation: bg-animation 10s infinite alternate;
}

.paragraph-header {
  color: white;
  font-size: 30px;
  align-content: center;
  padding-left: 5%;
  padding-right: 5%;
  padding-bottom: 20px;
}

.paragraph-text {
  color: white;
  font-size: 25px;
  align-content: center;
  padding-left: 5%;
  padding-right: 5%;
  padding-bottom: 20px;
  padding-top: 20px;
  overflow-wrap: break-word;
}

@media only screen and (max-width: 950px) {
  .paragraph-topdecoration:after {
    content: "";
    position: absolute;
    top: -20px;
    height: 20px;
    width: 100%;
    bottom: 140px;
    right: 0px;
    transform: translateY(50px);
    background: linear-gradient(90deg, #1b267e 0%, #3c0b46 100%);
    z-index: -1;
    -moz-transform: skew(0deg);
    -webkit-transform: skew(0deg);
  }
}

@media only screen and (max-width: 579px) {
  .paragraph-text {
    font-size: 16px;
  }
  .paragraph-header {
    padding-bottom: 0px;
  }
}

/* Background Animations */

@keyframes bg-animation {
  0% {
    background-position: 10% 0%;
  }
  50% {
    background-position: 91% 100%;
  }
  100% {
    background-position: 10% 0%;
  }
}

@-webkit-keyframes bg-animation {
  0% {
    background-position: 10% 0%;
  }
  50% {
    background-position: 91% 100%;
  }
  100% {
    background-position: 10% 0%;
  }
}
