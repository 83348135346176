body {
  font-family: "Spartan";
}

.footer-content {
  font-family: "Spartan";
  z-index: 50;
  color: white;
  background: #333333;
  height: auto;
  width: 100%;
  display: flex;
  justify-content: space-around;
  align-content: center;
  overflow-x: hidden;
  overflow-y: hidden;
}

.footer-col1,
.footer-col2,
.footer-col3 {
  display: inline-block;
  align-content: space-around;
  justify-content: space-around;
}

.footer-col1-list,
.footer-col2-list,
.footer-col3-list {
  padding: 0px;
  vertical-align: middle;
  text-decoration: none;
}

.footer-col2-list a,
.footer-col2-list p {
  color: white;
  text-align: center;
}

.footer-col3-list a,
.footer-col3-list p {
  color: white;
  text-align: right;
}

.footer-topdecoration {
  transform: translateY(130px);
  height: 30px;
  content: "";
  bottom: 20px;

  background: linear-gradient(45deg, #1b267e 0%, #3c0b46 100%);
  background-size: 200% 200%;
  animation: bg-animation 10s infinite alternate;
  -webkit-animation: bg-animation 10s infinite alternate;
}
@media only screen and (max-width: 650px) {
  .footer-col1,
  .footer-col2,
  .footer-col3,
  .footer-content {
    display: block;
  }
  .footer-col1-list a,
  .footer-col1-list p,
  .footer-col3-list a,
  .footer-col3-list p,
  .footer-col1-head,
  .footer-col2-head,
  .footer-col3-head {
    text-align: center;
  }
}

/* Background Animations */

@keyframes bg-animation {
  0% {
    background-position: 10% 0%;
  }
  50% {
    background-position: 91% 100%;
  }
  100% {
    background-position: 10% 0%;
  }
}

@-webkit-keyframes bg-animation {
  0% {
    background-position: 10% 0%;
  }
  50% {
    background-position: 91% 100%;
  }
  100% {
    background-position: 10% 0%;
  }
}
