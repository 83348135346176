.navbar {
  font-family: "Spartan";
  width: 100%;
  height: 100px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  background-color: rgba(0, 0, 0, 0.7);
  position: absolute;
  top: 0;
  z-index: 10;
  box-shadow: 0.2px 0.2px 10px rgb(0, 0, 0);
}

.brand-title {
  color: rgb(142, 68, 216);
  font-size: large;
  left: -20px;
  font-weight: 800;
}

.navbar ul {
  list-style: none;
}

.navbar li {
  display: inline-block;
}

.navbar li a {
  display: inline-block;
  margin-left: 40px;
  margin-right: 40px;
  border: none;
  cursor: pointer;
  position: relative;
  color: rgb(142, 68, 216);
  transition: color 0.2s;
  letter-spacing: 1px;
  text-decoration: none;
  padding: 20px;
  font-weight: 700;
  font-size: 1.4em;
}

.hamburger-items li a {
  display: inline-block;
  border: none;
  position: relative;
  color: rgb(142, 68, 216);
  transition: color 0.2s;
  letter-spacing: 1px;
  text-decoration: none;
  padding: 20px;
  font-weight: 700;
}

.navbar li a:after,
.hamburger-items li a:after {
  content: "";
  position: absolute;
  background-color: rgb(39, 51, 141);
  z-index: -1;
}
.navbar li a:hover,
.hamburger-items li a:hover {
  color: #fff;
  transition: all 0.5s;
}

/* Directions */
button.left:after {
  left: -0.3em;
  top: 0;
  height: 100%;
  width: 0.08em;
  transform: skew(-20deg);
  transition: width 0.1s;
}
.navbar li a:hover:after,
.hamburger-items li a:hover:after {
  width: calc(100% + 0.6em);
}

.navbar li a:after,
.hamburger-items li a:after {
  right: -0.3em;
  top: 0;
  height: 100%;
  width: 0.08em;
  transform: skew(-20deg);
  transition: width 0.1s;
}
.navbar li a:hover:after,
.hamburger-items li a:hover:after {
  width: calc(100% + 0.6em);
}

.hamburger {
  width: 35px;
  cursor: pointer;
  z-index: 1000;
  position: absolute;
  top: 25px;
  right: 30px;
}

.hamburger-menu {
  position: fixed;
  z-index: 100;
  min-width: 200px;
  max-width: 400px;
  width: 70%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.857);
  right: 0;
  top: 0;
  text-align: center;
}

.hamburger-items {
  list-style-type: none;
  text-align: center;
  padding: 25px 45px;
}

.hamburger-close {
  position: absolute;
  right: 10px;
  top: 10px;
  cursor: pointer;
}

.hamburger-item {
  list-style-type: none;
  padding: 20px;
  margin: 10px 0;
  margin-top: 20px;
  color: white;
  font-size: 30px;
}

.hamburger-background-blur {
  width: 100%;
  height: 100vh;
  position: fixed;
  background-color: rgba(95, 95, 95, 0.33);
  backdrop-filter: blur(6px);
  z-index: 25;
  right: 0;
  top: 0;
}

/* Hamburger Menu Functionality */

@media only screen and (min-width: 950px) {
  .navbar li {
    display: inline;
  }
  .hamburger,
  .hamburger-menu,
  .hamburger-background-blur {
    display: hidden;
    display: none;
  }
}

@media only screen and (max-width: 950px) {
  .navbar li {
    display: none;
  }
  .hamburger {
    display: block;
  }
}

@media only screen and (max-width: 579px) {
  .hamburger-menu li a {
    font-size: 1.2rem;
  }
}

@media only screen and (max-width: 320px) {
  .hamburger-menu li a {
    font-size: 1.1rem;
    padding: 20px 5px 20px 5px;
  }
}
