.project-popup-container {
  position: "fixed";
  top: "50%";
  left: "50%";
  transform: "translate(-50%, -50%)";
  background: "#FFF";
  padding: "50px";
  z-index: 1000;
}

.project-popup-heading {
  color: white;
  padding-left: 4%;
  font-size: 2rem;
}

.project-popup-content {
  color: white;
  padding-left: 4%;
  font-size: 1.5rem;
  width: 70%;
}

@media (max-width: 579px) {
  .project-popup-content {
    width: 92%;
  }
}
