.content-container {
  z-index: 1;
  position: relative;
}

.content-links {
  font-family: "Spartan";
  position: absolute;
  right: 0;
  z-index: 1;
  color: white;
  padding-top: 100px;
}

.content-text {
  font-family: "Spartan";
  position: absolute;
  z-index: 1;
  color: white;
  padding-left: 10%;
  padding-right: 10%;
  top: 70%;
}

.downcontent-text p {
  font-size: 1.2rem;
}

.downcontent-panel-top {
  height: 200px;
  clip-path: polygon(0 0, 50% 70px, 100% 0, 100% 100%, 0 100%);
  -webkit-clip-path: polygon(0 0, 50% 70px, 100% 0, 100% 100%, 0 100%);
  margin-top: -40px;

  background: linear-gradient(0deg, #1b267e 0%, #3c0b46 100%);
  background-size: 200% 200%;
  animation: bg-animation 10s infinite alternate;
  -webkit-animation: bg-animation 10s infinite alternate;
}

.downcontent-panel-bottom {
  height: 100px;
  clip-path: polygon(0% 100px, 50% 70px, 100% 100px, 100% 100%, 0 100%);
  -webkit-clip-path: polygon(0% 100px, 50% 70px, 100% 100px, 100% 100%, 0 100%);
  margin-top: -1px;
  transform: rotateX(180deg);
  z-index: 1;

  background: linear-gradient(0deg, #1b267e 0%, #3c0b46 100%);
  background-size: 200% 200%;
  animation: bg-animation 10s infinite alternate;
  -webkit-animation: bg-animation 10s infinite alternate;
}

.downborder-top {
  background-color: rgba(0, 0, 0, 0.7);
  position: relative;
  clip-path: polygon(0 0, 50% 70px, 100% 0, 100% 100%, 0 100%);
  margin-top: -330px;
  height: 150px;
  z-index: -2;
}

@media only screen and (max-width: 779px) {
  .downcontent-panel-top {
    margin-top: -20px;
    height: 150px;
  }
  .downborder-top {
    margin-top: -270px;
    height: 150px;
  }
  .downcontent-panel-bottom {
    height: 100px;
    margin-bottom: -20px;
  }
}

/* Background Animations */

@keyframes bg-animation {
  0% {
    background-position: 10% 0%;
  }
  50% {
    background-position: 91% 100%;
  }
  100% {
    background-position: 10% 0%;
  }
}

@-webkit-keyframes bg-animation {
  0% {
    background-position: 10% 0%;
  }
  50% {
    background-position: 91% 100%;
  }
  100% {
    background-position: 10% 0%;
  }
}
