html {
  height: 100%;
}

body {
  margin: 0;
  background-color: rgb(22, 22, 28);
  height: 100%;
  overflow-x: hidden;
}

.encompass-about {
  display: flex;
  flex-direction: column;
  height: 100vh;
}

.about-container {
  flex: 1 0 auto;
}

.about-content {
  position: relative;
  top: 160px;
}

footer {
  color: white;
  justify-self: flex-end;
  margin-top: 130px;
}

.about-icon {
  background-color: rgb(33, 33, 33);
  width: 100px;
  height: 100px;
  box-shadow: black 0px 0px 10px 1px;
  padding: 5px;
  margin: 9px;
}

.about-iconlist {
  text-align: center;
}

@media (max-width: 779px) {
  .about-content {
    top: 50px;
    margin-bottom: -170px;
  }
  .about-icon {
    size: 20px;
    font-size: 1.5em;
    height: 50px;
    width: 50px;
  }
}