body {
  margin: 0;
  padding: 0;
  background-color: #202d4d;
}

.banner-top {
  height: 500px;
  position: relative;
  width: 100%;
}

.banner-top img {
  width: 100%;
  height: 100%;
  background-size: cover;
  background-position: center center;
  z-index: -1;
}

.banner-top-heading {
  font-family: "Spartan";
  position: absolute;
  top: 40%;
  color: white;
  text-align: center;
  left: 50%;
  transform: translate(-50%, -50%);
  font-size: 5rem;
  text-shadow: 1px 1px 5px rgb(162, 162, 162);
  white-space: nowrap;
}

.banner-top-subheading {
  font-family: "Spartan";
  position: absolute;
  top: 60%;
  color: white;
  text-align: center;
  left: 50%;
  transform: translate(-50%, -50%);
  font-size: 1.5rem;
  text-shadow: 0.2px 0.2px 10px rgb(170, 170, 170);
}

.button-container {
  position: absolute;
  left: 50%;
  top: 77.5%;
  z-index: 10;
  transform: translate(-50%, -50%);
}

/* Size Styling */

@media (max-width: 979px) {
  .banner-top-heading {
    font-size: 4rem;
  }
}

@media (max-width: 779px) {
  .content,
  .content-lower {
    height: 300px;
  }
  .content-lower:before,
  .content-lower::after {
    top: 0;
  }

  .banner-top-subheading {
    font-size: 1.2rem;
    top: 62%;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: clip;
  }
}

@media (max-width: 579px) {
  .banner-top-heading {
    font-size: 3rem;
    padding-top: 50px;
  }
  .banner-top {
    height: 400px;
  }
}

@media (max-width: 379px) {
  .banner-top-heading {
    font-size: 2rem;
  }
}
