html {
  height: 100%;
}

body {
  margin: 0;
  background-color: rgb(22, 22, 28);
  height: 100%;
  overflow-x: hidden;
}

.encompass-home {
  display: flex;
  flex-direction: column;
  height: 100vh;
}

.home-container {
  flex: 1 0 auto;
}

.top-divider-banner {
  position: relative;
  top: -50px;
}

.second-divider-banner {
  position: relative;
  top: 450px;
  box-shadow: 10px 5px 5px black;
}

.content-1 {
  position: relative;
  top: 100px;
}

.content-2 {
  position: relative;
  top: 200px;
}

footer {
  color: white;
  justify-self: flex-end;
  margin-top: 130px;
}

@media (max-width: 779px) {
  .content-1 {
    top: 40px;
  }
  .content-2 {
    top: 100px;
    margin-bottom: -115px;
  }
}