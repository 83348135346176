html {
  height: 100%;
}

body {
  margin: 0;
  background-color: rgb(22, 22, 28);
  height: 100%;
  overflow-x: hidden;
}

.encompass-projects {
  display: flex;
  flex-direction: column;
  height: 100vh;
}

.projects-container {
  flex: 1 0 auto;
}

.top-divider-banner {
  position: relative;
  bottom: -40px;
}

.project1-display {
  position: relative;
  top: 100px;
  transition: top 1s, left 1s;
}

.project2-display {
  position: relative;
  top: 150px;
  transition: all 0.5s;
}

.project3-display {
  position: relative;
  top: 200px;
}

footer {
  color: white;
  justify-self: flex-end;
  margin-top: 130px;
}

@media (max-width: 779px) {
  .project1-display {
    top: 40px;
  }
  .project2-display {
    top: 70px;
  }
  .project3-display {
    top: 100px;
    margin-bottom: -120px;
  }
}